import React, { useState } from 'react'
import { RoutesContainer } from './RoutesContainer'
import { ThemeProvider } from 'styled-components'
import GlobalStyle, { default_theme } from './assets/styles/global.js'
import { NavBar, Footer } from './components'
import { BrowserRouter } from 'react-router-dom'

export const App = () => {
  const [transaction, setTransaction] = useState('sale')

  return (
    <ThemeProvider theme={default_theme}>
      <GlobalStyle />
      <BrowserRouter>
        <NavBar transaction={transaction} />
        <RoutesContainer transaction={transaction} setTransaction={setTransaction} />
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  )
}
