import styled from 'styled-components'

export const Title = styled.h1`
  width: 100%;
  font-size: 24px;
  margin: 20px 20px 0 20px;
`

export const Details = styled.p`
  width: 100%;
  margin: 0 20px;
`

export const FilterContainer = styled.div`
  position: absolute;
  top: 100px;
  z-index: 9999;
  width: 98vw;
  margin: 0 auto;

  & > div {
    border: 1px solid ${props => props.theme.input.border};
  }
`

export const InfoBoxContainer = styled.div`
  padding: 5px 0 5px 5px;

  & > a {
    border: 0;

    &:hover {
      border: 0;
    }
  }
`

export const NotFound = styled.div`
  width: 70%;
  margin: 60px auto;
  text-align: center;

  & svg {
    width: 200px;
    height: 200px;
  }

  & h1 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 500;
  }

  & p {
    text-align: left;
    line-height: 2;
    font-size: 15px;
  }
`

export const InfoboxContainer = styled.div`
  & div.infoBox {
    width: 70px;
  }
`

export const Pin = styled.div`
  background-color: white;
  padding: 20px;
  width: 200px;
`

export const OrderIcon = styled.div`
  border: 1px solid ${props => props.theme.input.border};
  height: 65px;
  cursor: pointer;
  transition: 0.3s all;

  & > svg {
    height: 65px;
    width: 65px;
    padding: 20px;
    color: ${props => props.theme.input.placeholder};
  }

  &:hover {
    border: 1px solid ${props => props.theme.input.border_hover};
  }
`
