import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { get_property } from '../../services/requests'
import { transactionEncode } from '../../utils/transactionType'
import { Container, ContainerFlex, Loader, Map } from '../../components/'
import {
  GalleryGrid,
  Title,
  Code,
  District,
  NotFound,
  Icon,
  IconsContainer,
  Description,
  Commodities,
  Price,
  ShowImages,
  Info,
  ButtonSwitch,
  VideoContainer
} from './styles'
import { ReactComponent as NotFoundImg } from '../../assets/images/search_house.svg'
import { AiFillCar, AiFillPlayCircle } from 'react-icons/ai'
import { FaBath, FaBed, FaHome, FaRulerHorizontal } from 'react-icons/fa'
import { BiFullscreen } from 'react-icons/bi'
import { Marker } from 'react-leaflet'
import { moneyCorrect } from '../../utils/moneyCorrect'
import FsLightbox from 'fslightbox-react'
import { ReactPhotoSphereViewer } from 'react-photo-sphere-viewer'

export const Property = ({ setTransaction }) => {
  let { transaction, id } = useParams()

  const [property, setProperty] = useState(null)
  const [error, setError] = useState(false)

  const [showGallery, setShowGallery] = useState(false)
  const [show, setShow] = useState('images')
  const [galleryData, setGalleryData] = useState({
    images: null,
    videos: null
  })

  const getInitialData = async () => {
    try {
      const { data } = await get_property(id, transactionEncode(transaction))

      if (data[`for_${transactionEncode(transaction)}`]) setProperty(data)
      else setError(true)

      setGalleryData({
        images: data.images,
        videos: data.videos
      })
    } catch (error) {
      if (error.response.status === 404) setError(true)
      else
        alert(
          'Ocorreu um erro ao carregar os dados do imóvel, tente novamente mais tarde ou entre em contato conosco para mais informações.'
        )
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    setTransaction(transactionEncode(transaction))
    getInitialData()
    // eslint-disable-next-line
  }, [])

  return property ? (
    <ContainerFlex margin='20px auto' direction='column' maxWidth='1120px' mobileWidth='90vw'>
      {show === 'images' && (
        <GalleryGrid onClick={() => setShowGallery(!showGallery)}>
          {property.images.slice(0, 5).map((img, index) => {
            return (
              <img
                src={img}
                alt={property.id}
                key={index}
                className={index === 0 ? 'featured' : ''}
              />
            )
          })}
          <ShowImages>
            <p>Galeria de Fotos</p>
            <BiFullscreen />
          </ShowImages>
        </GalleryGrid>
      )}

      {show === 'videos' && (
        <FsLightbox toggler={showGallery} sources={galleryData[show]} type='youtube' />
      )}
      {show === 'images' && (
        <FsLightbox toggler={showGallery} sources={galleryData[show]} type='image' />
      )}

      {show === 'map' && (
        <Container maxWidth='1120px' height='400px' margin='0 0 20px 0'>
          <Map position={{ lat: property.latitude, lng: property.longitude }} zoom={15}>
            <Marker position={{ lat: property.latitude, lng: property.longitude }} />
          </Map>
        </Container>
      )}

      {show === 'videos' && (
        <VideoContainer
          background={galleryData['images'][0]}
          onClick={() => setShowGallery(!showGallery)}
        >
          <AiFillPlayCircle />
        </VideoContainer>
      )}

      {show === '360' && (
        <Container maxWidth='1120px' height='400px' margin='0 0 20px 0'>
          <ReactPhotoSphereViewer
            src='https://iili.io/Jde6UoN.jpg'
            height={'100%'}
            width={'100%'}
          />
        </Container>
      )}

      <ContainerFlex direction='row' gap='10px'>
        <ButtonSwitch onClick={() => setShow('images')} selected={show === 'images'}>
          Fotos
        </ButtonSwitch>

        {/* <ButtonSwitch onClick={() => setShow('360')} selected={show === '360'}>
          Tour Virtual
        </ButtonSwitch> */}

        {galleryData.videos && (
          <ButtonSwitch onClick={() => setShow('videos')} selected={show === 'videos'}>
            Vídeos
          </ButtonSwitch>
        )}

        <ButtonSwitch onClick={() => setShow('map')} selected={show === 'map'}>
          Mapa
        </ButtonSwitch>
      </ContainerFlex>
      <Code>Código {property.id}</Code>
      <Title>
        {property.publicArea} {property.address}, {property.number} - {property.city}
      </Title>
      <District>{property.district}</District>
      <Price>
        {transaction === 'temporada' ? 'Valor aproximado' : null} R${' '}
        {moneyCorrect(property[`${transactionEncode(transaction)}_price`])}
      </Price>
      {transaction === 'temporada' && (
        <Info>
          Prezados clientes, comunicamos que no período de Natal, Ano Novo, carnaval e datas
          comemorativas os imóveis administrados pela Imobiliária Toni Neutziling possuem pacotes de
          diárias diferenciadas, favor consultar com nossos atendentes.
        </Info>
      )}
      <IconsContainer>
        <Icon transaction={transactionEncode(transaction)}>
          <FaHome />
          <p>{property.building_area} m²</p>
        </Icon>

        <Icon transaction={transactionEncode(transaction)}>
          <FaRulerHorizontal />
          <p>
            {property.front_area} x {property.back_area}
          </p>
        </Icon>

        <Icon transaction={transactionEncode(transaction)}>
          <FaBed />
          <p>
            {property.bedrooms} Quarto{property.bedrooms > 1 ? 's' : null}
          </p>
        </Icon>
        <Icon transaction={transactionEncode(transaction)}>
          <FaBath />
          <p>
            {property.bathrooms} Banheiro{property.bathrooms > 1 ? 's' : null}
          </p>
        </Icon>
        <Icon transaction={transactionEncode(transaction)}>
          <AiFillCar />
          <p>
            {property.parking_space ? property.parking_space : 'Nenhuma'} Vaga
            {property.parking_space > 1 ? 's' : null} de Garagem
          </p>
        </Icon>
      </IconsContainer>
      <Description>{property.description}</Description>
      {property.commodities.length > 0 && (
        <Commodities>
          <h1>Comodidades</h1>
          <ul>
            {property.commodities.map(commodity => (
              <li key={commodity}>{commodity}</li>
            ))}
          </ul>
        </Commodities>
      )}
    </ContainerFlex>
  ) : error ? (
    <NotFound>
      <NotFoundImg />
      <h1>Imóvel não encontrado</h1>
      <p style={{ textAlign: 'center' }}>
        Infelizmente não encontramos o imóvel selecionado! Verifique se a modalidade selecionada
        está correta para este imóvel. Se necessário entre em contato conosco que iremos encontrar o
        imóvel perfeito para você!
      </p>
    </NotFound>
  ) : (
    <ContainerFlex margin='30vh 0' align='center' justify='center'>
      <Loader />
    </ContainerFlex>
  )
}
