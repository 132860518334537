import React, { useState } from 'react'
import { ContainerFlex } from '../../'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardArrowUp } from 'react-icons/md'
import { Arrow, PageIndicator, DropdownContainer } from './styles'
import { Item } from '../SelectInput/styles'

export const Pagination = ({ pageCount, handleChangePage, currentPage }) => {
  const list = [...Array(pageCount).keys(), pageCount]
  const [focus, setFocus] = useState(false)
  const [element, setElement] = useState(null)

  const handleClick = () => {
    setFocus(!focus)
    document.addEventListener('click', handleClickOutside)
  }

  const handleClickOutside = e => {
    if (!element.contains(e.target)) {
      setFocus(false)
      document.removeEventListener('click', handleClickOutside)
    }
  }

  return (
    <ContainerFlex gap='15px'>
      {currentPage !== 0 && (
        <Arrow onClick={() => handleChangePage('prev')}>
          <MdKeyboardArrowLeft />
        </Arrow>
      )}
      <PageIndicator onClick={handleClick} focus={focus} ref={node => setElement(node)}>
        <p>Página {currentPage}</p> <MdKeyboardArrowUp />
        {focus && (
          <DropdownContainer>
            {list.map(item => {
              return <Item onClick={() => handleChangePage(item)}>Página {item}</Item>
            })}
          </DropdownContainer>
        )}
      </PageIndicator>

      {currentPage !== pageCount && (
        <Arrow onClick={() => handleChangePage('next')}>
          <MdKeyboardArrowRight />
        </Arrow>
      )}
    </ContainerFlex>
  )
}
