import React from 'react'

export const Rent = () => {
  return (
    <div style={{ height: '85vh' }}>
      <iframe
        src='https://0530-sitepadrao.sicadiweb.com.br/'
        width='100%'
        height='100%'
        title='aluguel'
        style={{ border: 'none' }}
      />
    </div>
  )
}
