import axios from 'axios'

const __API__ = process.env.REACT_APP_SERVER_PROD

export default axios.create({
  baseURL: __API__,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
})
