import React from 'react'
/* import { GoogleMap, useJsApiLoader } from '@react-google-maps/api' */
import { MapContainer, TileLayer } from 'react-leaflet'

const containerStyle = {
  width: '100%',
  height: '100%'
}

const center = {
  lat: -31.366708,
  lng: -51.97159
}

/* function MyComponent({ children, position, zoom }) {
  const [zoomReset, setZoomReset] = useState(0)
  const [positionMap, setPositionMap] = useState(center)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBWthUiYc1jOfGwHGV5_wREzwdFLfokAgk'
  })

  //eslint-disable-next-line
  const [map, setMap] = useState(null)

  const onLoad = React.useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds(center)
      map.fitBounds(bounds)

      setMap(map)

      setTimeout(() => {
        setZoomReset(zoom)
        if (position) {
          setPositionMap(position)
        }
      }, 1000)
    },
    [zoom, position]
  )

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={positionMap}
      zoom={zoomReset}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {children}
    </GoogleMap>
  ) : (
    <></>
  )
}

export const Map = React.memo(MyComponent) */

export const Map = ({ position, children, zoom, style }) => {
  return (
    <MapContainer
      center={position ? position : center}
      zoom={zoom ? zoom : 14}
      scrollWheelZoom={true}
      style={style ? style : containerStyle}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      {children}
    </MapContainer>
  )
}
