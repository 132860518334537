import styled from 'styled-components'

export const ImageContainer = styled.div`
  background-image: url(${props => props.src});
  height: 350px;
  width: 100%;
  background-position: center;
`

export const Title = styled.h1`
  font-weight: 500;
  text-align: center;
  margin: 0 auto;
`

export const Text = styled.p`
  line-height: 1.7;
  font-size: 16px;
  width: ${props => props.width};

  @media (max-width: 500px) {
    width: 100%;
  }
`

export const Image = styled.img`
  width: 50%;

  @media (max-width: 500px) {
    margin-top: 30px;
    width: 100%;
  }
`
