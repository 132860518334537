import React, { useState } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { Dropdown, Item, Label, Input, Container, Search } from './styles'
import { MdClose } from 'react-icons/md'
import { InputLoader } from '../../index'

export const SelectInput = ({
  text,
  inactive,
  value,
  options,
  size,
  handleSelection,
  name,
  clear,
  error,
  loading,
  clearButton
}) => {
  const [focus, setFocus] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [element, setElement] = useState(null)

  const handleClick = () => {
    if (options) {
      setFocus(!focus)
      setSearchTerm('')
      document.addEventListener('click', handleClickOutside)
    }
  }

  const handleClickOutside = e => {
    if (!element.contains(e.target)) {
      setFocus(false)
      document.removeEventListener('click', handleClickOutside)
    }
  }

  return (
    <Container ref={node => setElement(node)}>
      <Input onClick={handleClick} focus={focus} size={size} error={error}>
        <Label selected={value !== null} focus={focus} error={error}>
          <span>{text}</span>
          <p>{value ? entry(value) : null}</p>
        </Label>
        {value && clearButton && <MdClose className='clear' onClick={() => clear(name)} />}
        {loading || !options ? <InputLoader /> : <MdKeyboardArrowDown className='arrow' />}
      </Input>
      {focus ? (
        <Dropdown size={size}>
          <Search placeholder='Procurar' onChange={event => setSearchTerm(event.target.value)} />
          {options &&
            options
              .filter(val => {
                if (searchTerm === '') return val
                else if (val.toLowerCase().includes(searchTerm.toLowerCase())) return val
                return false
              })
              .map(option => {
                return (
                  <Item
                    key={option.id}
                    selected={option === value}
                    onClick={() => {
                      setFocus(false)
                      setSearchTerm('')
                      handleSelection(name, option)
                    }}
                  >
                    {option}
                  </Item>
                )
              })}
        </Dropdown>
      ) : null}
    </Container>
  )
}

export const SelectInputValue = ({
  text,
  disabled,
  value,
  options,
  size,
  handleSelection,
  name,
  clear,
  error,
  loading,
  clearButton
}) => {
  const [focus, setFocus] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const [element, setElement] = useState(null)

  const handleClick = () => {
    if (!disabled && options) {
      setFocus(!focus)
      setSearchTerm('')
      document.addEventListener('click', handleClickOutside)
    }
  }

  const handleClickOutside = e => {
    if (!element.contains(e.target)) {
      setFocus(false)
      document.removeEventListener('click', handleClickOutside)
    }
  }

  const checkSelection = (value, options) => {
    if (verifyNulls(value) && options) {
      return entry(options.find(elem => elem.id === value).name)
    }
    return null
  }

  return (
    <Container ref={node => setElement(node)}>
      <Input onClick={handleClick} focus={focus} size={size} error={error}>
        <Label selected={verifyNulls(value)} focus={focus} error={error}>
          <span>{text}</span>
          <p>{checkSelection(value, options)}</p>
        </Label>
        {value !== null && clearButton && <MdClose className='clear' onClick={() => clear(name)} />}
        {loading || !options ? <InputLoader /> : <MdKeyboardArrowDown className='arrow' />}
      </Input>
      {focus ? (
        <Dropdown size={size}>
          <Search placeholder='Procurar' onChange={event => setSearchTerm(event.target.value)} />
          {options &&
            options
              .filter(val => {
                if (searchTerm === '') return val
                else if (val.name.toLowerCase().includes(searchTerm.toLowerCase())) return val
                return false
              })
              .map(option => {
                return (
                  <Item
                    key={option.id}
                    selected={option.id === value}
                    onClick={() => {
                      setFocus(false)
                      setSearchTerm('')
                      handleSelection(name, option.id)
                    }}
                  >
                    {option.name}
                  </Item>
                )
              })}
        </Dropdown>
      ) : null}
    </Container>
  )
}

export const SelectInputBoolean = ({
  text,
  inactive,
  value,
  options,
  size,
  handleSelection,
  name,
  clear,
  loading
}) => {
  const [focus, setFocus] = useState(false)

  const [element, setElement] = useState(null)

  const handleClick = () => {
    if (options) {
      setFocus(!focus)
      document.addEventListener('click', handleClickOutside)
    }
  }

  const handleClickOutside = e => {
    if (!element.contains(e.target)) {
      setFocus(false)
      document.removeEventListener('click', handleClickOutside)
    }
  }

  return (
    <Container ref={node => setElement(node)}>
      <Input onClick={handleClick} focus={focus} size={size}>
        <Label selected={true} focus={focus}>
          <span>{text}</span>
          <p>{value ? entry(options[1].name) : entry(options[0].name)}</p>
        </Label>
        {value && <MdClose className='clear' onClick={() => clear(name)} />}
        {loading || !options ? <InputLoader /> : <MdKeyboardArrowDown className='arrow' />}
      </Input>
      {focus ? (
        <Dropdown size={size}>
          {options &&
            options.map(option => {
              return (
                <Item
                  key={option.value}
                  selected={option.value === value}
                  onClick={() => {
                    setFocus(false)
                    handleSelection(name, option.value)
                  }}
                >
                  {option.name}
                </Item>
              )
            })}
        </Dropdown>
      ) : null}
    </Container>
  )
}

export const SelectDate = ({
  text,
  inactive,
  value,
  size,
  handleSelection,
  error,
  handleClick
}) => {
  return (
    <Container>
      <Input onClick={handleClick} size={size} error={error}>
        <Label selected={value !== null} error={error}>
          <span>{text}</span>
          <p>{value ? entry(value) : null}</p>
        </Label>
        <MdKeyboardArrowDown className='arrow' />
      </Input>
    </Container>
  )
}

//Auxiliar Functions

const verifyNulls = value => {
  return value !== null && value !== undefined
}

// Slice if text is too long
const entry = text => {
  return text.length > 17 ? `${text.slice(0, 15)}...` : text
}
