import styled from 'styled-components'
import { ContainerGrid } from '../Container'
import { Button, SimpleButton } from '../Styled/Button'
import { handleSize } from '../Styled/Utils'

export const FilterBar = styled.div`
  padding: 30px 0;
  width: 90vw;
  background: ${props => props.theme.general.filter_background};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
  position: relative;
`

export const FilterTransaction = styled.div`
  width: 90%;
  border-bottom: 2px solid ${props => props.theme.transaction[props.transaction]};
  display: flex;
  gap: 20px;
  padding-bottom: 15px;
`

export const TransactionOption = styled.p`
  background: ${props =>
    props.selected
      ? props.theme.transaction[props.transaction]
      : props.theme.general.filter_background};
  color: ${props => (props.selected ? 'white' : props.theme.general.text)};
  font-weight: 500;
  cursor: pointer;
  padding: 10px;
  transition: 0.3s all;

  &:hover {
    background: ${props => props.theme.transaction[props.transaction]};
    color: white;
  }
`

export const Container = styled(ContainerGrid)`
  & div.react-datepicker {
    border-radius: 0;
  }

  & > div.react-datepicker-wrapper {
    width: 200px;
  }

  & > div.react-datepicker__tab-loop {
    position: absolute;
  }

  & div.react-datepicker-popper div.react-datepicker {
    font-family: 'IBM Plex Sans', sans-serif;
    border: 1px solid ${props => props.theme.date_picker.border};
    color: ${props => props.theme.text.primary};
    background: ${props => props.theme.date_picker.background};
  }

  & div.react-datepicker__header {
    background: ${props => props.theme.date_picker.background};
  }

  & button.react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-color: ${props => props.theme.date_picker.button_color};
  }

  & button.react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-width: 1px 1px 0 0;
  }

  & button.react-datepicker__navigation {
    border-radius: 25px;
  }

  & button.react-datepicker__navigation:hover {
    background: ${props => props.theme.date_picker.button_color_hover};
  }

  & button .react-datepicker__navigation-icon {
    top: 0;
  }

  & button .react-datepicker__navigation-icon--next {
    left: 0px;
  }

  & button .react-datepicker__navigation-icon--previous {
    right: 0px;
  }

  & .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    border-bottom-color: ${props => props.theme.date_picker.border};
  }

  & .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 25px;
    background: ${props => props.theme.date_picker.option_background};
    color: ${props => props.theme.date_picker.option_color};
  }

  & .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: 25px;
    background: ${props => props.theme.date_picker.option_background};
    color: ${props => props.theme.date_picker.option_color};
  }

  & .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    font-weight: 400;
  }

  & .react-datepicker__day:hover,
  .react-datepicker__month-text--today:hover,
  .react-datepicker__quarter-text--today:hover,
  .react-datepicker__year-text--today:hover {
    border-radius: 25px;
  }

  @media (max-width: 500px) {
    & > div.react-datepicker-wrapper {
      width: 100%;
    }
  }
`

export const CodeContainer = styled.div`
  position: relative;

  @media (max-width: 500px) {
    width: 100%;
  }
`

export const ButtonCodeAction = styled(Button)`
  width: 100%;
  height: 35px;
`

export const ButtonCodeCancel = styled(SimpleButton)`
  width: 100%;
  height: 30px;
`

export const CodeModal = styled.div`
  list-style-type: none;
  background: ${props => props.theme.input.dropdown_background};
  width: ${props => handleSize(props.size)};
  z-index: 2;
  position: absolute;
  top: 75px;
  border: 1px solid ${props => props.theme.input.border};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 5px;

  @media (max-width: 499px) {
    width: 100%;
  }
`

export const CodeInput = styled.input`
  width: 100%;
  height: 40px;
  padding: 20px;
  outline: none;
  border: 1px solid
    ${props => (props.error ? props.theme.input.border_error : props.theme.input.border)};
  transition: 0.4s all;
  color: ${props => props.theme.text.default};
  font-weight: 500;
  font-size: 14px;
  caret-color: ${props => (props.inactive ? 'white' : '')};
  margin-bottom: 10px;
`

export const CloseButton = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  color: ${props => props.theme.text.default};
  border-radius: 20px;
  padding: 7px 7px 4px 7px;
  transition: 0.3s all;

  &:hover {
    background: ${props => props.theme.input.button_background_hover};
  }
`
