import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { Home, Property, Results, About, Contact, Rent } from './containers'

export const RoutesContainer = ({ transaction, setTransaction }) => {
  return (
    <Routes>
      <Route
        path='/'
        element={<Home transaction={transaction} setTransaction={setTransaction} />}
      />
      <Route
        path='/resultados/:query'
        element={<Results transaction={transaction} setTransaction={setTransaction} />}
      />
      <Route
        path='/imovel/:transaction/:id'
        element={<Property transaction={transaction} setTransaction={setTransaction} />}
      />
      <Route
        path='/sobre'
        element={<About transaction={transaction} setTransaction={setTransaction} />}
      />
      <Route
        path='/contato'
        element={<Contact transaction={transaction} setTransaction={setTransaction} />}
      />
      <Route
        path='/aluguel'
        element={<Rent transaction={transaction} setTransaction={setTransaction} />}
      />
    </Routes>
  )
}
