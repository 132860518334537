import React from 'react'
import { Label } from './styles'
import InputMask from 'react-input-mask'
import { MdClose } from 'react-icons/md'
import { InputLoader } from '../Loader'

export const TextInput = ({
  type,
  text,
  name,
  disabled,
  value,
  handleChange,
  size,
  mask,
  maskChar,
  loading,
  error,
  errorMessage,
  clear
}) => {
  return (
    <Label size={size} error={error}>
      <InputMask
        type={type}
        value={value}
        mask={mask ? mask : ''}
        name={name}
        onChange={handleChange}
        required
        disabled={disabled}
        maskChar={maskChar ? maskChar : '_'}
      />
      <span>{text}</span>
      {loading ? (
        <InputLoader size={size} />
      ) : value ? (
        <MdClose onClick={() => clear(name)} />
      ) : null}
      {error && <p>{errorMessage}</p>}
    </Label>
  )
}
