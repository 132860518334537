export const moneyCorrect = value => {
  let price = String(value)

  if (price.length === 6) {
    return price.slice(0, 3) + '.' + price.slice(3, 6) + ',00'
  } else if (price.length === 7) {
    return price.slice(0, 1) + '.' + price.slice(1, 4) + '.' + price.slice(4, 7) + ',00'
  } else if (price.length === 5) {
    return price.slice(0, 2) + '.' + price.slice(2, 5) + ',00'
  } else if (price.length === 8) {
    return price.slice(0, 2) + '.' + price.slice(2, 5) + '.' + price.slice(5, 8) + '.00'
  } else {
    return price
  }
}
