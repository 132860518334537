import styled from 'styled-components'
import { RegularButton } from '../../components'

export const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 200px;
  gap: 15px;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & img.featured {
    grid-column: span 2;
    grid-row: span 2;
  }

  & > div {
    opacity: 0.7;
  }

  &:hover > div {
    opacity: 1;
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const ShowImages = styled.div`
  display: flex;
  position: absolute;
  top: 20px;
  right: 20px;
  background: white;
  padding: 15px 15px 13px 15px;
  cursor: pointer;
  color: ${props => props.theme.button.regular_text};
  font-weight: 500;
  font-size: 14px;
  transition: 0.3s all;
  gap: 10px;

  &:hover {
    background: ${props => props.theme.button.regular_background_hover};
  }

  & > svg {
    width: 20px;
    height: 20px;
  }
`

export const Code = styled.span`
  font-size: 12px;
  color: ${props => props.theme.cards.subtext};
  margin-top: 20px;
  margin-bottom: 10px;
`

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
`

export const District = styled.p`
  font-size: 15px;
`

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  svg {
  }
`

export const IconsContainer = styled.div`
  display: flex;
  margin: 15px 0 20px 0;
  padding-bottom: 20px;
  gap: 25px;
  border-bottom: 1px solid ${props => props.theme.cards.border};

  p {
    font-size: 14px;
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }
`

export const Icon = styled.div`
  display: flex;

  & svg {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    color: ${props => props.theme.transaction[props.transaction]};
  }
`

export const Description = styled.p`
  line-height: 2;
  font-size: 15px;
  margin-bottom: 20px;
`

export const Price = styled.p`
  font-size: 15px;
  font-weight: 500;
  margin-top: 10px;
`

export const Commodities = styled.div`
  margin-bottom: 20px;

  & h1 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  & ul {
    margin-left: 15px;
  }
`

export const NotFound = styled.div`
  width: 70%;
  margin: 120px auto;
  text-align: center;

  & svg {
    width: 200px;
    height: 200px;
  }

  & h1 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 500;
  }

  & p {
    text-align: left;
    line-height: 2;
    font-size: 15px;
  }
`

export const Info = styled.span`
  font-size: 14px;
  margin-top: 10px;
  color: #f56565;
  line-height: 1.7;
`

export const ButtonSwitch = styled(RegularButton)`
  height: 40px;
`

export const VideoContainer = styled.div`
  background-image: url(${props => props.background});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  max-width: 1120px;
  height: 400px;
  margin: 0 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    & svg {
      color: ${props => props.theme.button.default_background_hover};
    }
  }

  & svg {
    width: 100px;
    height: 100px;
    color: ${props => props.theme.button.default_background};
    transition: 0.3s all;
  }
`
