import styled from 'styled-components'

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 15px;
  text-align: center;
`

export const Description = styled.p`
  font-size: 14px;
  margin-bottom: 35px;
  text-align: center;
`

export const Icon = styled.div`
  width: 35px;
  height: 35px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  & svg {
    width: 35px;
    height: 35px;
    border: 1px solid
      ${props => (props.disabled ? props.theme.input.border : props.theme.button.regular_border)};
    color: ${props =>
      props.disabled ? props.theme.input.border : props.theme.button.regular_border};
    border-radius: 30px;
    padding: 7px 8px 7px 7px;
    transition: 0.3s all;

    &:hover {
      background: ${props => (props.disabled ? '' : props.theme.button.regular_background_hover)};
    }
  }
`
