export const handleSize = size => {
  switch (size) {
    case 'small':
      return '200px'
    case 'medium':
      return '450px'
    case 'big':
      return '700px'
    case 'textbox':
      return '700px'
    case 'login':
      return '100%'
    case 'full':
      return '100%'
    default:
      return '200px'
  }
}
