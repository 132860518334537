import React from 'react'
import { ContainerFlex } from '../../components'
import { ImageContainer, Title, Text, Image } from './styles'
import background from '../../assets/images/about.jpg'
import background1 from '../../assets/images/about1.jpg'

export const About = () => {
  return (
    <>
      <ImageContainer src={background} />
      <ContainerFlex maxWidth='1120px' margin='30px auto'>
        <Title>Quem Somos</Title>
        <ContainerFlex
          direction='row'
          maxWidth='1120px'
          margin='40px'
          justify='space-between'
          align='center'
          mobileDirection='column'
        >
          <Text width='100%'>
            <strong>O Início</strong>
            <br />
            <br />
            Em 1975, Otoni Juvenil Neutzling, fundador da "Imobiliária Toni Neutzling", com o
            objetivo de tornar acessível a intermediação de propriedades rurais e a compra da casa
            própria, fundou a “Imobiliária Santa Isabel”.
            <br />
            <br />
            Sendo pioneira na época, veio trazendo os negócios com boa aceitação no mercado. Assim,
            precisando expandir a empresa contou com seu filho Antonio Tadeu Neutzling, para
            ajudá-lo a tornar melhor tudo o que estava acontecendo.
          </Text>
        </ContainerFlex>

        <ContainerFlex
          direction='row'
          maxWidth='1120px'
          margin='0px 40px 40px 40px'
          justify='space-between'
          align='center'
          mobileDirection='column'
        >
          <Text width='45%'>
            <strong>A Empresa Hoje</strong>
            <br />
            <br />
            Hoje a empresa com a maior estrutura em negócios imobiliários, rurais, gestão
            condominial, também uma carteira referência em imóveis de temporada. Posição esta,
            alcançada pelo comprometimento, eficiência e inovação constante em produtos e serviços,
            visando oferecer as melhores soluções para nossos clientes.
            <br />
            <br />
            No início desta década, promovemos uma grande mudança organizacional rumo à excelência.
            Temos investido na gestão profissional focada no aperfeiçoamento constante de nossas
            equipes, novas tecnologias de informação, foco e determinação.
            <br />
            <br />
            Preservamos a postura nos negócios e nos relacionamentos interpessoais, agregamos
            valores para os clientes.
            <br />
            <br />
            Por isso, competimos de forma ética para o crescimento constante desta empresa, líder no
            mercado. Compromisso e dedicação fazem de nós referência há mais de 40 anos!
            <br />
            <br />
            <strong>ACREDITE NA ESCOLHA.</strong>
          </Text>
          <Image src={background1} alt='background' />
        </ContainerFlex>
      </ContainerFlex>
    </>
  )
}
