import styled from 'styled-components'
import { handleSize } from '../Utils'

export const Button = styled.button`
  display: block;
  width: ${props => handleSize(props.size)};
  border: 0;
  height: 65px;
  background: ${props => props.theme.button.default_background};
  transition: 0.4s all;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  margin: ${props => props.margin};

  color: ${props => props.theme.button.default_text};
  font-weight: 500;
  font-size: 14px;

  &:hover {
    background: ${props => props.theme.button.default_background_hover};
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`

export const SecondaryButton = styled(Button)`
  background: ${props => props.theme.button.secondary_background};
  color: ${props => props.theme.button.secondary_text};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  &:hover {
    background: ${props => props.theme.button.secondary_background_hover};
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`

export const SimpleButton = styled(Button)`
  background: ${props => props.theme.button.simple_background};
  color: ${props => props.theme.button.simple_text};
  margin: 0 10px;

  &:hover {
    color: ${props => props.theme.button.simple_text_hover};
    background: ${props => props.theme.button.simple_background_hover};
  }
`

export const RegularButton = styled(Button)`
  border: 1px solid ${props => props.theme.button.regular_border};
  background: ${props =>
    props.selected ? props.theme.button.regular_border : props.theme.button.regular_background};
  color: ${props =>
    props.selected ? props.theme.button.regular_background : props.theme.general.text};
  font-weight: 500;
  font-size: 14px;

  &:hover {
    background: ${props =>
      props.selected
        ? props.theme.button.regular_border
        : props.theme.button.regular_background_hover};
    color: ${props =>
      props.selected
        ? props.theme.button.regular_background
        : props.theme.button.regular_text_hover};
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`
