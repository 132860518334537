import React from 'react'
import background from '../../assets/images/background.avif'
import { FilterContainer } from './styles'
import { ContainerFlex, Filter, Featured } from '../../components'

export const Home = ({ transaction, setTransaction }) => {
  return (
    <ContainerFlex direction='column'>
      <FilterContainer backgroundImage={`url(${background})`}>
        <Filter transaction={transaction} setTransaction={setTransaction} />
      </FilterContainer>
      <Featured transaction={transaction} setTransaction={setTransaction} />
    </ContainerFlex>
  )
}
