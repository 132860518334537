import styled from 'styled-components'
import { handleSize } from '../Utils'
import { Dropdown } from '../SelectInput/styles'

const Button = styled.div`
  cursor: pointer;
  background: ${props => props.theme.button.regular};
  padding: 15px;
  transition: 0.3s all;
  font-weight: 500;
  color: ${props => props.theme.input.border};
  font-size: 14px;
  border: 1px solid ${props => props.theme.input.border};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;

  &:hover {
    border: 1px solid ${props => props.theme.button.regular_border};
    color: ${props => props.theme.button.regular_border};
  }
`

export const Arrow = styled(Button)`
  & svg {
    width: 20px;
    height: 20px;
  }
`

export const PageIndicator = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: ${props => handleSize(props.size)};
  height: 65px;
  border: 1px solid
    ${props => (props.focus ? props.theme.input.border_active : props.theme.input.border)};
  color: ${props => props.theme.general.text};
  transition: 0.3s all;
  font-weight: 500;
  font-size: 14px;
  user-select: none;

  &:hover {
    border: 1px solid
      ${props => (props.focus ? props.theme.input.border_active : props.theme.input.border_hover)};
  }

  & p {
    color: ${props => props.theme.general.text};
    margin-left: 15px;
  }

  & svg {
    margin-right: 10px;
    margin-top: 2px;
    color: ${props => props.theme.input.button};
    width: ${props => props.theme.input.button_width};
    height: ${props => props.theme.input.button_height};
    padding: ${props => props.theme.input.button_padding};
    transition: 0.4s all;
    border-radius: 50%;
  }

  &:hover svg {
    color: ${props => props.theme.input.button_hover};
    background: ${props => props.theme.input.button_background_hover};
  }
`

export const DropdownContainer = styled(Dropdown)`
  top: -210px;
`
