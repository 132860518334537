import React, { useState, useEffect, forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { IoMdClose } from 'react-icons/io'
import {
  FilterBar,
  FilterTransaction,
  TransactionOption,
  Container,
  CodeModal,
  ButtonCodeAction,
  ButtonCodeCancel,
  CodeInput,
  CodeContainer,
  CloseButton
} from './styles'
import { optionsInput, pricesRange } from '../../utils/optionsInput'
import { transactionDecode } from '../../utils/transactionType'
import { Button, RegularButton, SelectDate, SelectInputValue, TextInput } from '../'
import axios from 'axios'
import { get_cities, get_districts, get_types } from '../../services/requests'
import moment from 'moment'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import pt from 'date-fns/locale/pt'
registerLocale('pt', pt)

export const Filter = ({ transaction, setTransaction, modal, closeModal, submit }) => {
  let navigate = useNavigate()

  const [form, setForm] = useState({
    city: null,
    district: null,
    type: null,
    address: null,
    number: null,
    bedrooms: null,
    bathrooms: null,
    parking_spaces: null,
    minPrice: null,
    maxPrice: null,
    initial_date: null,
    final_date: null,
    transaction: transaction
  })

  const [cities, setCities] = useState(null)
  const [districts, setDistricts] = useState(null)
  const [types, setTypes] = useState(null)
  const [startDate, setStartDate] = useState(new Date())
  const [selectedStartDate, setSelectStartDate] = useState(false)
  const [endDate, setEndDate] = useState(new Date())
  const [selectedEndDate, setSelectEndDate] = useState(false)

  const [more, setMore] = useState(false)
  const [showCode, setShowCode] = useState(false)
  const [code, setCode] = useState(null)

  const handleInput = value => {
    setForm({ ...form, [value.target.name]: value.target.value })
  }

  const handleSelect = (name, value) => {
    setForm({ ...form, [name]: value })
  }

  const getInitialData = async () => {
    try {
      let promises = await axios.all([get_cities(), get_districts(414, 'sale'), get_types()])
      setCities(promises[0].data)
      setDistricts(promises[1].data)
      setTypes(promises[2].data)
      setForm({ ...form, city: 414 })
    } catch (err) {
      console.log(err)
      alert('Erro ao carregar dados')
    }
  }

  const changeTransaction = async type => {
    if (type === 'rent') {
      window.open('https://0530-sitepadrao.sicadiweb.com.br/', '_self')
      return true
    }

    setTransaction(type)
    setForm({
      ...form,
      transaction: type,
      minPrice: null,
      maxPrice: null,
      initial_date: null,
      final_date: null
    })
    setStartDate(new Date())
    setSelectStartDate(true)
    setEndDate(new Date('2026-05-25'))
    setSelectEndDate(true)

    setDistricts(null)
    try {
      let res = await get_districts(414, type)
      setDistricts(res.data)
    } catch (err) {
      console.log(err)
      alert('Erro ao carregar dados')
    }
  }

  const SelectComponent = forwardRef(({ value, onClick, text, type, error }, ref) => (
    <SelectDate
      handleClick={onClick}
      ref={ref}
      value={handleDate(value, type)}
      text={`Data de ${text}`}
      error={error}
    />
  ))

  const handleChangeCity = async (name, value) => {
    setDistricts(null)
    setForm({ ...form, [name]: value })
    try {
      let promise = await get_districts(value)
      setDistricts(promise.data)
    } catch (err) {
      console.log(err)
      alert('Erro ao carregar dados')
    }
  }

  const handleDateSelection = (type, date) => {
    if (type === 'start') {
      setStartDate(date)
      setSelectStartDate(true)
      setForm({ ...form, initial_date: moment(date).format('YYYY-MM-DD') })
    } else {
      setEndDate(date)
      setSelectEndDate(true)
      setForm({ ...form, final_date: moment(date).format('YYYY-MM-DD') })
    }
  }

  const handleDate = (value, type) => {
    if (type === 'start')
      if (selectedStartDate) return moment(value).format('DD/MM/YYYY')
      else return null
    else if (selectedEndDate) return moment(value).format('DD/MM/YYYY')
    else return null
  }

  const handleSubmit = () => {
    let data = {
      ...form,
      city: cities.find(city => city.id === form.city).name,
      district: form.district
        ? districts.find(district => district.id === form.district).name
        : null
    }

    if (transaction !== 'season') {
      navigate(`/resultados/${JSON.stringify(data)}`)
      if (modal) submit(data)
    } else if (selectedStartDate && selectedEndDate) {
      navigate(`/resultados/${JSON.stringify(data)}`)
      if (modal) submit(data)
    } else alert('Selecione as datas de entrada e saída')
  }

  const handleCode = () => {
    if (code) navigate(`/imovel/${transactionDecode(transaction)}/${code}`)
    else alert('Preencha o código do imóvel!')
  }

  const handleCodeChange = value => {
    setCode(value.target.value)
  }

  useEffect(() => {
    if (transaction === 'rent') {
      setTransaction('sale')
    }

    getInitialData()

    /* document.addEventListener('keypress', function (e) {
      if (e.key === 'Enter') {
        handleSubmit()
      }
    }) */
    // eslint-disable-next-line
  }, [])

  return (
    <FilterBar>
      {modal && (
        <CloseButton onClick={closeModal}>
          <IoMdClose />
        </CloseButton>
      )}
      <FilterTransaction transaction={transaction}>
        <TransactionOption
          selected={transaction === 'sale'}
          transaction={transaction}
          onClick={() => changeTransaction('sale')}
        >
          Comprar
        </TransactionOption>
        <TransactionOption
          selected={transaction === 'rent'}
          transaction={transaction}
          onClick={() => changeTransaction('rent')}
        >
          Alugar
        </TransactionOption>
        <TransactionOption
          selected={transaction === 'season'}
          transaction={transaction}
          onClick={() => changeTransaction('season')}
        >
          Temporada
        </TransactionOption>
      </FilterTransaction>

      <Container width='90%' min='200px' gap='15px' justify='center'>
        <SelectInputValue
          text='Cidade'
          name='city'
          options={cities}
          value={form.city}
          handleSelection={handleChangeCity}
          clear={name => {
            setForm({ ...form, [name]: 414 })
            handleChangeCity(name, 414)
          }}
        />

        <SelectInputValue
          text='Bairro'
          name='district'
          options={districts}
          value={form.district}
          handleSelection={handleSelect}
          clear={name => setForm({ ...form, [name]: null })}
          clearButton={true}
        />

        <SelectInputValue
          text='Tipo de Imóvel'
          name='type'
          options={types}
          value={form.type}
          handleSelection={handleSelect}
          clear={name => setForm({ ...form, [name]: null })}
          clearButton={true}
        />

        {transaction === 'sseason' && (
          <DatePicker
            selected={startDate}
            onChange={date => handleDateSelection('start', date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            customInput={<SelectComponent text='Entrada' type='start' />}
          />
        )}

        {transaction === 'sseason' && (
          <DatePicker
            selected={endDate}
            onChange={date => handleDateSelection('end', date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            customInput={<SelectComponent text='Saída' type='end' />}
          />
        )}

        <SelectInputValue
          text='Quartos'
          options={optionsInput.quantity}
          handleSelection={handleSelect}
          name='bedrooms'
          value={form.bedrooms}
          clear={name => setForm({ ...form, [name]: null })}
          clearButton={true}
        />

        <SelectInputValue
          text='Valor Mínimo'
          name='minPrice'
          value={form.minPrice}
          options={pricesRange[transaction]}
          handleSelection={handleSelect}
          clear={name => setForm({ ...form, [name]: null })}
          clearButton={true}
        />

        <SelectInputValue
          text='Valor Máximo'
          name='maxPrice'
          value={form.maxPrice}
          options={pricesRange[transaction]}
          handleSelection={handleSelect}
          clear={name => setForm({ ...form, [name]: null })}
          clearButton={true}
        />

        <TextInput
          text='Endereço'
          name='address'
          value={form.address}
          handleChange={handleInput}
          clear={name => setForm({ ...form, [name]: '' })}
        />

        <TextInput
          text='Número'
          name='number'
          value={form.number}
          handleChange={handleInput}
          clear={name => setForm({ ...form, [name]: '' })}
        />

        {more && (
          <SelectInputValue
            text='Banheiros'
            options={optionsInput.quantity}
            handleSelection={handleSelect}
            name='bathrooms'
            value={form.bathrooms}
            clear={name => setForm({ ...form, [name]: null })}
            clearButton={true}
          />
        )}
        {more && (
          <SelectInputValue
            text='Garagem'
            options={optionsInput.quantity}
            handleSelection={handleSelect}
            name='parking_spaces'
            value={form.parking_spaces}
            clear={name => setForm({ ...form, [name]: null })}
            clearButton={true}
          />
        )}
      </Container>
      <Container width='90%' min='200px' gap='15px' justify='center'>
        {!modal && (
          <CodeContainer>
            <RegularButton onClick={() => setShowCode(!showCode)}>
              Pesquisar por Código
            </RegularButton>
            {showCode && (
              <CodeModal>
                <CodeInput placeholder='Digite o código' onChange={handleCodeChange} />
                <ButtonCodeAction onClick={handleCode}>Acessar Imóvel</ButtonCodeAction>
                <ButtonCodeCancel onClick={() => setShowCode(false)}>Cancelar</ButtonCodeCancel>
              </CodeModal>
            )}
          </CodeContainer>
        )}
        <RegularButton onClick={() => setMore(!more)}>
          {more ? 'Menos' : 'Mais'} Opções
        </RegularButton>
        <Button onClick={handleSubmit}>Encontrar Imóveis</Button>
      </Container>
    </FilterBar>
  )
}
