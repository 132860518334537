import styled from 'styled-components'

export const FooterContainer = styled.div`
  width: 100%;
  background: ${props => props.theme.footer.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 50px;
  gap: 40px;

  & img {
    width: 200px;
    margin: 0 auto;
  }
`

export const LocationsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 500px) {
    flex-direction: column;
    gap: 30px;
  }
`

export const Location = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  gap: 20px;

  & h1 {
    color: ${props => props.theme.footer.text};
    font-weight: 600;
    font-size: 18px;
  }
`

export const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  &:hover {
    svg {
      color: ${props => props.theme.footer.text_hover};
    }

    a {
      color: ${props => props.theme.footer.text_hover};
    }
  }

  & svg {
    color: ${props => props.theme.footer.text};
    transition: 0.3s all;
  }

  & a {
    color: ${props => props.theme.footer.text};
    font-size: 14px;
    transition: 0.3s all;
    font-weight: 500;
  }
`

export const SocialMedia = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  margin: 0 auto;

  & svg {
    color: ${props => props.theme.footer.text};
    width: 30px;
    height: 30px;
    transition: 0.3s all;

    &:hover {
      color: ${props => props.theme.footer.text_hover};
    }
  }
`
