import styled from 'styled-components'

//Generic simple div container
export const Container = styled.div`
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  width: ${props => props.width};
  height: ${props => props.height};
  position: ${props => props.position};
  max-width: ${props => props.maxWidth};
`

export const ContainerFlex = styled(Container)`
  display: flex;
  flex-direction: ${props => props.direction};
  align-items: ${props => props.align};
  justify-content: ${props => props.justify};
  gap: ${props => props.gap};
  flex-wrap: wrap;

  @media (max-width: 500px) {
    flex-direction: ${props => props.mobileDirection};
    gap: ${props => props.mobileGap};
    display: ${props => props.mobileDisplay};
    flex-wrap: ${props => props.mobileWrap};
    justify-content: ${props => props.mobileJustify};
    align-items: ${props => props.mobileAlign};
    width: ${props => props.mobileWidth};
    height: ${props => props.mobileHeight};
    margin: ${props => props.mobileMargin};
    padding: ${props => props.mobilePadding};
  }
`

export const ContainerGrid = styled(Container)`
  display: grid;
  grid-template-columns: ${props => `repeat(auto-fill, minmax(${props.min}, auto))`};
  align-items: ${props => props.align};
  justify-items: ${props => props.justify};
  gap: ${props => props.gap};

  @media (max-width: 500px) {
    flex-direction: ${props => props.mobileDirection};
    gap: ${props => props.mobileGap};
    display: ${props => props.mobileDisplay};
    flex-wrap: ${props => props.mobileWrap};
    justify-content: ${props => props.mobileJustify};
    align-items: ${props => props.mobileAlign};
    width: ${props => props.mobileWidth};
    height: ${props => props.mobileHeight};
    margin: ${props => props.mobileMargin};
    padding: ${props => props.mobilePadding};
  }
`
