import React from 'react'
import { Card, Image, Title, IconsContainer, Icon, NoImage, SubTitle, Price } from './styles'
import { AiFillCar } from 'react-icons/ai'
import { FaBath, FaBed } from 'react-icons/fa'
import { ContainerFlex } from '../'
import logo from '../../assets/images/logo_simple.png'
import { transactionDecode } from '../../utils/transactionType'
import { moneyCorrect } from '../../utils/moneyCorrect'

export const PropertyCard = ({ data, transaction, onMouseEnter, onMouseLeave }) => {
  return (
    <Card
      to={`/imovel/${transactionDecode(transaction)}/${data.id}`}
      transaction={transaction}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {data.image ? (
        <Image src={data.image} alt={data.id} />
      ) : (
        <NoImage>
          <img src={logo} alt='Sem Imagem' />
        </NoImage>
      )}
      <ContainerFlex margin='10px 20px' direction='column' gap='10px'>
        <span>Código {data.id}</span>
        <div>
          <Title>
            {data.address}, {data.number}
          </Title>
          <SubTitle>{data.district}</SubTitle>
        </div>
        <IconsContainer>
          <Icon transaction={transaction}>
            <FaBed />
            <p>{data.bedrooms}</p>
          </Icon>
          <Icon transaction={transaction}>
            <AiFillCar />
            <p>{data.parking_space}</p>
          </Icon>
          <Icon transaction={transaction}>
            <FaBath />
            <p>{data.bathrooms}</p>
          </Icon>
        </IconsContainer>
        <Price season={transaction === 'season'}>
          {transaction === 'season' ? 'Valor aproximado ' : null}R$: {moneyCorrect(data.price)}
        </Price>
      </ContainerFlex>
    </Card>
  )
}
