import React from 'react'
import logo from '../../assets/images/logo.png'
import { Container, LinksContainer } from './styles'
import { DefaultLink, PureLink } from '../index'
import { Link } from 'react-router-dom'

export const NavBar = ({ transaction }) => {
  return (
    <Container type={transaction}>
      <Link to='/'>
        <img src={logo} alt='Logo' />
      </Link>
      <LinksContainer>
        <DefaultLink to='/'>Início</DefaultLink>
        <DefaultLink to='/sobre'>Sobre</DefaultLink>
        <DefaultLink to='/contato'>Contato</DefaultLink>
        <PureLink href='https://admin.imobiliariatoni.com.br/' target='_blank'>
          Restrito
        </PureLink>
      </LinksContainer>
    </Container>
  )
}
