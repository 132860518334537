export const optionsInput = {
  quantity: [
    { id: 0, name: 'Nenhum' },
    { id: 1, name: '1+' },
    { id: 2, name: '2+' },
    { id: 3, name: '3+' },
    { id: 4, name: '4+' },
    { id: 5, name: '5+' }
  ],
  field_orientation_options: ['Frente', 'Meio', 'Fundos'],
  solarPosition: ['Norte', 'Sul', 'Leste', 'Oeste']
}

export const pricesRange = {
  sale: [
    { id: 50000, name: '50.000' },
    { id: 100000, name: '100.000' },
    { id: 150000, name: '150.000' },
    { id: 200000, name: '200.000' },
    { id: 250000, name: '250.000' },
    { id: 300000, name: '300.000' },
    { id: 350000, name: '350.000' },
    { id: 400000, name: '400.000' },
    { id: 450000, name: '450.000' },
    { id: 500000, name: '500.000' },
    { id: 550000, name: '550.000' },
    { id: 600000, name: '600.000' },
    { id: 650000, name: '650.000' },
    { id: 700000, name: '700.000' },
    { id: 750000, name: '750.000' },
    { id: 800000, name: '800.000' },
    { id: 850000, name: '850.000' },
    { id: 900000, name: '900.000' },
    { id: 950000, name: '950.000' },
    { id: 1000000, name: '1.000.000' },
    { id: 1500000, name: '1.500.000' },
    { id: 2000000, name: '2.000.000' },
    { id: 2500000, name: '2.500.000' },
    { id: 3000000, name: '3.000.000' }
  ],
  season: [
    { id: 50, name: '50' },
    { id: 100, name: '100' },
    { id: 150, name: '150' },
    { id: 200, name: '200' },
    { id: 250, name: '250' },
    { id: 300, name: '300' },
    { id: 350, name: '350' },
    { id: 400, name: '400' },
    { id: 450, name: '450' },
    { id: 500, name: '500' },
    { id: 550, name: '550' },
    { id: 600, name: '600' },
    { id: 650, name: '650' },
    { id: 700, name: '700' },
    { id: 750, name: '750' },
    { id: 800, name: '800' },
    { id: 850, name: '850' },
    { id: 900, name: '900' },
    { id: 950, name: '950' },
    { id: 1000, name: '1.000' },
    { id: 1500, name: '1.500' },
    { id: 2000, name: '2.000' },
    { id: 2500, name: '2.500' },
    { id: 3000, name: '3.000' }
  ],

  rent: [
    { id: 400, name: '400' },
    { id: 450, name: '450' },
    { id: 500, name: '500' },
    { id: 550, name: '550' },
    { id: 600, name: '600' },
    { id: 650, name: '650' },
    { id: 700, name: '700' },
    { id: 750, name: '750' },
    { id: 800, name: '800' },
    { id: 850, name: '850' },
    { id: 900, name: '900' },
    { id: 950, name: '950' },
    { id: 1000, name: '1.000' },
    { id: 1500, name: '1.500' },
    { id: 2000, name: '2.000' },
    { id: 2500, name: '2.500' },
    { id: 3000, name: '3.000' },
    { id: 3500, name: '3.500' },
    { id: 4000, name: '4.000' },
    { id: 4500, name: '4.500' },
    { id: 5000, name: '5.000' }
  ]
}
