import React from 'react'
import logo from '../../assets/images/logo-branco.png'
import { FiMapPin, FiMail } from 'react-icons/fi'
import { BsWhatsapp, BsInstagram, BsFacebook } from 'react-icons/bs'
import { FooterContainer, Info, Location, LocationsContainer, SocialMedia } from './styles'

export const Footer = () => {
  return (
    <FooterContainer>
      <img src={logo} alt='Logo' />
      <LocationsContainer>
        <Location>
          <h1>Filial Centro</h1>
          <Info>
            <FiMapPin />
            <a href='https://goo.gl/maps/hqqcqqZE8eDFxdDv7'>Av. Cel. Alfredo Born, 477</a>
          </Info>
          <Info>
            <BsWhatsapp />
            <a href='https://api.whatsapp.com/send?phone=555332513133'>(53) 3251-3133</a>
          </Info>
        </Location>
        <Location>
          <h1>Matriz</h1>
          <Info>
            <FiMapPin />
            <a href='https://goo.gl/maps/cBdDUZyDRgNWT7hFA'>Rua Júlio de Castilhos, 1430</a>
          </Info>
          <Info>
            <BsWhatsapp />
            <a href='https://api.whatsapp.com/send?phone=555332513133'>(53) 3251-3133</a>
          </Info>
        </Location>
        <Location>
          <h1>Filial Praia</h1>
          <Info>
            <FiMapPin />
            <a href='https://maps.app.goo.gl/iyyUDy85UiPHhqgG6'>
              Av. Getúlio Vargas, 458 - sala 06
            </a>
          </Info>
          <Info>
            <BsWhatsapp />
            <a href='https://api.whatsapp.com/send?phone=555332513133'>(53) 3251-3133</a>
          </Info>
        </Location>
      </LocationsContainer>
      <SocialMedia>
        <a href='https://instagram.com/imobiliariatoni'>
          <BsInstagram />
        </a>
        <a href='https://www.facebook.com/imobiliariatoni'>
          <BsFacebook />
        </a>
        <a href='mailto:vendas@imobiliariatoni.com.br'>
          <FiMail />
        </a>
      </SocialMedia>
    </FooterContainer>
  )
}
