export const transactionDecode = transaction => {
  switch (transaction) {
    case 'sale':
      return 'venda'
    case 'rent':
      return 'aluguel'
    case 'season':
      return 'temporada'
    default:
      return 'venda'
  }
}

export const transactionEncode = transaction => {
  switch (transaction) {
    case 'venda':
      return 'sale'
    case 'aluguel':
      return 'rent'
    case 'temporada':
      return 'season'
    default:
      return 'sale'
  }
}

export const transactionId = transaction => {
  switch (transaction) {
    case 'sale':
      return 1
    case 'rent':
      return 2
    case 'season':
      return 3
    default:
      return 1
  }
}
