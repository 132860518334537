import React, { useState } from 'react'
import { Button, ButtonLoader, ContainerFlex, Textbox, TextInput } from '../../components/'
import { Form, Title, Text, body } from './styles'
import { sendEmail } from '../../services/requests'

export const Contact = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      await sendEmail(body(name, email, phone, message))
      reset()
      alert('Email enviado com sucesso!')
    } catch (e) {
      alert('Erro ao enviar o email, por favor tente mais tarde!')
      console.error(e)
    }
    setLoading(false)
  }

  const reset = () => {
    setName('')
    setEmail('')
    setPhone('')
    setMessage('')
  }

  return (
    <ContainerFlex margin='30px auto' maxWidth='1120px' direction='column'>
      <Title>Entre em contato conosco</Title>
      <Text>Deixe sua mensagem que nossa equipe entrará em contato com você!</Text>
      <Form>
        <TextInput
          type='text'
          text='Nome Completo'
          name='name'
          value={name}
          handleChange={event => setName(event.target.value)}
          clear={() => setName('')}
          size='big'
        />
        <TextInput
          type='text'
          text='Email'
          name='email'
          value={email}
          handleChange={event => setEmail(event.target.value)}
          clear={() => setEmail('')}
          size='big'
        />
        <TextInput
          type='text'
          text='Celular'
          name='phone'
          value={phone}
          mask='(99) 99999-9999'
          handleChange={event => setPhone(event.target.value)}
          clear={() => setPhone('')}
          size='big'
        />

        <Textbox
          size='big'
          text='Mensagem'
          value={message}
          handleChange={event => setMessage(event.target.value)}
        />

        <Button size='big' onClick={handleSubmit}>
          {loading ? <ButtonLoader /> : 'Enviar Mensagem'}
        </Button>
      </Form>
    </ContainerFlex>
  )
}
