import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  ContainerFlex,
  ContainerGrid,
  Loader,
  Pagination,
  PropertyCard,
  RegularButton,
  Map,
  Filter,
  SelectInputValue
} from '../../components'
import { getFilteredProperties } from '../../services/requests'
import { HiSortAscending, HiSortDescending } from 'react-icons/hi'
import { Title, Details, FilterContainer, NotFound, OrderIcon } from './styles'
/* import { MarkerF, InfoWindowF } from '@react-google-maps/api' */
import { ReactComponent as NotFoundImg } from '../../assets/images/search_house.svg'
import { Marker, Popup } from 'react-leaflet'
import axios from 'axios'

export const Results = ({ transaction, setTransaction }) => {
  let params = JSON.parse(useParams().query)

  // For showing all the markers on the map
  const [allData, setAllData] = useState(null)
  // For showing only the filtered data
  const [data, setData] = useState(null)
  const page_items = 30
  const [page, setPage] = useState(0)
  const [total_pages, setTotalPages] = useState(0)
  const [order, setOrder] = useState('price')
  const [orderDirection, setOrderDirection] = useState('asc')

  const [selectedProperty, setSelectedProperty] = useState(null)
  const [showFilter, setShowFilter] = useState(false)
  const [loading, setLoading] = useState(false)

  const getInitialData = async () => {
    let params_filter = { ...params, page, page_items, order, order_direction: orderDirection }
    let params_all = {
      ...params,
      page,
      page_items: 'MAX_VALUE',
      order,
      order_direction: orderDirection
    }

    try {
      let promise = await axios.all([
        getFilteredProperties(params_filter),
        getFilteredProperties(params_all)
      ])
      setData(promise[0].data.properties)
      setTotalPages(promise[0].data.total_pages)
      setAllData(promise[1].data.properties)
    } catch (error) {
      console.log(error)
      alert('Erro ao carregar dados')
    }
  }

  const getData = async form => {
    let params_filter = { ...form, page, page_items, order, order_direction: orderDirection }
    let params_all = {
      ...form,
      page,
      page_items: 'MAX_VALUE',
      order,
      order_direction: orderDirection
    }

    try {
      let promise = await axios.all([
        getFilteredProperties(params_filter),
        getFilteredProperties(params_all)
      ])
      setData(promise[0].data.properties)
      setTotalPages(promise[0].data.total_pages)
      setAllData(promise[1].data.properties)
    } catch (error) {
      console.log(error)
      alert('Erro ao carregar dados')
    }
  }

  const resetData = () => {
    setData(null)
    setAllData(null)
    setOrder('price')
    setOrderDirection('asc')
    setSelectedProperty(null)
    setShowFilter(false)
    setPage(0)
    setTotalPages(0)
  }

  const handleOrder = async (type, id) => {
    setLoading(true)
    setPage(0)
    setSelectedProperty(null)

    if (type === 'orderDirection') {
      let direction = orderDirection === 'asc' ? 'desc' : 'asc'
      setOrderDirection(direction)

      try {
        let promise = await getFilteredProperties({
          ...params,
          page: 0,
          page_items,
          order,
          order_direction: direction
        })
        setData(promise.data.properties)
        setLoading(false)
      } catch (error) {
        console.log(error)
        alert('Erro ao carregar dados')
      }
    } else {
      setOrder(id)
      try {
        let promise = await getFilteredProperties({
          ...params,
          page: 0,
          page_items,
          order: id,
          order_direction: orderDirection
        })
        setData(promise.data.properties)
        setLoading(false)
      } catch (error) {
        console.log(error)
        alert('Erro ao carregar dados')
      }
    }
  }

  const handleChangePage = async type => {
    let params_filter
    setLoading(true)

    if (type === 'next') {
      if (page !== total_pages) {
        setPage(page + 1)
        params_filter = {
          ...params,
          page: page + 1,
          page_items,
          order,
          order_direction: orderDirection
        }
      }
    } else if (type === 'prev') {
      if (page !== 0) {
        setPage(page - 1)
        params_filter = {
          ...params,
          page: page - 1,
          page_items,
          order,
          order_direction: orderDirection
        }
      }
    } else {
      setPage(type)
      params_filter = { ...params, page: type, page_items, order, order_direction: orderDirection }
    }

    try {
      let promise = await getFilteredProperties(params_filter)
      setData(promise.data.properties)
      setLoading(false)
    } catch (error) {
      console.log(error)
      alert('Erro ao carregar dados')
    }
  }

  useEffect(() => {
    setTransaction(params.transaction)
    getInitialData()
    // eslint-disable-next-line
  }, [])

  return (
    <ContainerFlex>
      {showFilter && (
        <FilterContainer>
          <Filter
            transaction={transaction}
            setTransaction={setTransaction}
            modal={true}
            closeModal={() => setShowFilter(false)}
            query={params}
            submit={form => {
              resetData()
              setTransaction(form.transaction)
              getData(form)
            }}
          />
        </FilterContainer>
      )}

      {data ? (
        data.length > 0 ? (
          <ContainerFlex>
            {loading ? (
              <ContainerFlex
                height='80vh'
                width='calc(65vw - 15px)'
                mobileWidth='100vw'
                align='center'
                justify='center'
              >
                <Loader />
              </ContainerFlex>
            ) : (
              <ContainerFlex
                direction='row'
                gap='20px'
                height='80vh'
                style={{ overflowY: 'scroll' }}
                width='calc(65vw - 15px)'
                mobileWidth='100vw'
              >
                <Title>Resultados da pesquisa</Title>
                <Details>{allData.length} imóveis foram encontrados</Details>
                <ContainerFlex margin='0 20px' gap='20px' width='100%'>
                  <SelectInputValue
                    text='Ordenar por'
                    value={order}
                    options={[
                      { name: 'Valor', id: 'price' },
                      { name: 'Quartos', id: 'bedrooms' },
                      { name: 'Banheiros', id: 'bathrooms' },
                      { name: 'Código', id: 'id' }
                    ]}
                    name='order'
                    handleSelection={(name, id) => handleOrder('order', id)}
                  />
                  <OrderIcon onClick={() => handleOrder('orderDirection')}>
                    {orderDirection === 'asc' ? <HiSortDescending /> : <HiSortAscending />}
                  </OrderIcon>
                  <RegularButton onClick={() => setShowFilter(true)}>Filtrar</RegularButton>
                </ContainerFlex>
                <ContainerGrid
                  width='65vw'
                  min='200px'
                  gap='15px'
                  justify='center'
                  margin='20px 20px 0 20px'
                  mobileWidth='100vw'
                >
                  {data &&
                    !loading &&
                    data.map(property => (
                      <PropertyCard
                        data={property}
                        transaction={transaction}
                        key={property.id}
                        /* onMouseEnter={() => setSelectedProperty(property)}
                        onMouseLeave={() => setSelectedProperty(null)} */
                      />
                    ))}
                </ContainerGrid>

                <ContainerFlex margin='20px auto'>
                  <Pagination
                    pageCount={total_pages}
                    handleChangePage={handleChangePage}
                    currentPage={page}
                  />
                </ContainerFlex>
              </ContainerFlex>
            )}

            <ContainerFlex width='35vw' height='80vh' mobileDisplay='none'>
              <Map
                position={
                  params.city === 'São Lourenço do Sul'
                    ? null
                    : {
                        lat: data[0].latitude,
                        lng: data[0].longitude
                      }
                }
                zoom={14}
              >
                {allData.map(property => {
                  return (
                    <Marker
                      position={{ lat: property.latitude, lng: property.longitude }}
                      key={property.id}
                    >
                      <Popup>
                        <PropertyCard data={property} transaction={transaction} />
                      </Popup>
                    </Marker>
                  )
                })}
                {/* {selectedProperty && (
                  <InfoWindowF
                    key={selectedProperty.id}
                    position={{ lat: selectedProperty.latitude, lng: selectedProperty.longitude }}
                    onCloseClick={() => setSelectedProperty(null)}
                  >
                    <InfoBoxContainer>
                      <PropertyCard data={selectedProperty} transaction={transaction} />
                    </InfoBoxContainer>
                  </InfoWindowF>
                )} */}
              </Map>
            </ContainerFlex>
          </ContainerFlex>
        ) : (
          <NotFound>
            <NotFoundImg />
            <h1>Sem Resultados</h1>
            <p>
              Infelizmente não encontramos um imóvel com as específicações que você procura. Se
              necessário entre em contato conosco que iremos encontrar o imóvel perfeito para você!
            </p>
          </NotFound>
        )
      ) : (
        <ContainerFlex margin='30vh auto'>
          <Loader />
        </ContainerFlex>
      )}
    </ContainerFlex>
  )
}
