import styled from 'styled-components'
import { handleSize } from '../Utils'

export const Input = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: ${props => handleSize(props.size)};
  height: 65px;
  border: 1px solid
    ${props =>
      props.error
        ? props.theme.input.border_error
        : props.focus
        ? props.theme.input.border_active
        : props.theme.input.border};
  background: ${props => props.theme.input.background};
  transition: 0.3s all;
  font-weight: 500;
  font-size: 14px;
  user-select: none;

  &:hover {
    border: 1px solid
      ${props => (props.focus ? props.theme.input.border_active : props.theme.input.border_hover)};
  }

  & svg {
    margin-right: 15px;
    margin-top: 2px;
    color: ${props => props.theme.input.button};
    width: ${props => props.theme.input.button_width};
    height: ${props => props.theme.input.button_height};
    padding: ${props => props.theme.input.button_padding};
    transition: 0.4s all;
    border-radius: 50%;
  }

  & svg.arrow {
    background: ${props => (props.focus ? props.theme.input.button_background_hover : '')};
  }

  &:hover svg.arrow {
    color: ${props => props.theme.input.button_hover};
    background: ${props => props.theme.input.button_background_hover};
  }

  & svg.clear {
    position: absolute;
    top: 20px;
    left: ${props => `calc(${handleSize(props.size)} - 65px)`};
    cursor: pointer;

    &:hover {
      color: ${props => props.theme.input.button_hover};
      background: ${props => props.theme.input.button_background_hover};
    }
  }

  @media (max-width: 499px) {
    width: 100%;

    & svg.clear {
      left: calc(100% - 70px);
    }
  }
`

export const Label = styled.div`
  & span {
    position: absolute;
    left: 16px;
    top: 24px;
    transition: 0.3s ease;
    font-weight: 500;
    user-select: none;
    color: ${props =>
      props.error
        ? props.theme.input.border_error
        : props.focus
        ? props.theme.input.placeholder_active
        : props.theme.input.placeholder};
    transform: ${props => (props.selected ? 'translateY(-10px)' : '')};
    font-size: ${props => (props.selected ? '13px' : '')};
  }

  p {
    padding: 18px 16px 0;
  }
`

export const Dropdown = styled.ul`
  list-style-type: none;
  background: ${props => props.theme.input.dropdown_background};
  width: ${props => handleSize(props.size)};

  z-index: 2;
  overflow-y: scroll;
  height: 200px;
  position: absolute;
  top: 75px;
  border: 1px solid ${props => props.theme.input.border};

  scrollbar-width: thin;
  scrollbar-color: #babac0 rgba(0, 0, 0, 0);

  &::-webkit-scrollbar {
    background-color: rgba(0, 0, 0, 0);
    width: 15px;
    border-radius: 10px;
    opacity: 0;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
    color: rgba(0, 0, 0, 0);
    opacity: 0;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }

  @media (max-width: 499px) {
    width: 100%;
  }
`

export const Item = styled.li`
  margin: 5px 2px 5px 10px;
  cursor: pointer;
  transition: 0.3s all;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
  padding: 5px 10px;
  color: ${props => (props.selected ? props.theme.input.placeholder_active : '')};
  background: ${props => (props.selected ? props.theme.input.dropdown_option_hover : '')};

  &:hover {
    background: ${props => props.theme.input.dropdown_option_hover};
  }
`

export const Container = styled.div`
  position: relative;

  @media (max-width: 500px) {
    width: 100%;
  }
`

export const Search = styled.input`
  outline: none;
  text-align: center;
  border: 0;
  border-bottom: 1px solid rgba(161, 161, 161, 0.1);
  width: 90%;
  height: 40px;
  margin-left: 13px;
  transition: 0.3s all;
  font-size: 13px;
  font-weight: 500;
  color: ${props => props.theme.text.primary};

  &::placeholder {
    font-weight: 500;
  }
`
