import styled from 'styled-components'
import { handleSize } from '../Utils'

export const Label = styled.label`
  position: relative;
  display: block;

  & span {
    position: absolute;
    left: 16px;
    top: 24px;
    color: ${props => props.theme.input.placeholder};
    font-weight: 500;
    transition: 0.3s ease;
  }

  &:hover textarea {
    border: 1px solid ${props => props.theme.input.border_hover};
  }

  & textarea:valid + span,
  & textarea:focus + span {
    transform: translateY(-10px);
    font-size: 13px;
  }

  & textarea:valid,
  & textarea:focus {
    border: 1px solid ${props => props.theme.input.border_active};
  }

  & textarea:focus + span {
    color: ${props => props.theme.input.border_active};
  }
`

export const InputTextbox = styled.textarea`
  height: 150px;
  min-height: 150px;
  max-width: ${props => handleSize(props.size)};
  min-width: ${props => handleSize(props.size)};
  padding: 35px 15px 15px;
  outline: none;
  border: 1px solid ${props => props.theme.input.border};
  transition: 0.4s all;
  color: ${props => props.theme.text.primary};
  font-weight: 500;
  font-size: 14px;
  caret-color: ${props => (props.inactive ? 'white' : '')};

  &:focus {
    border: 1px solid ${props => props.theme.input.border_active};
  }

  &::placeholder {
    padding-top: 20px;
    color: ${props => props.theme.input.placeholder};
  }
`
