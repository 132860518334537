import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const DefaultLink = styled(Link)`
  font-weight: 400;
  color: ${props => props.theme.link.default};
  transition: 0.3s all;

  &:hover {
    color: ${props => props.theme.link.hover};
  }
`

export const PureLink = styled.a`
  font-weight: 400;
  color: ${props => props.theme.link.default};
  transition: 0.3s all;

  &:hover {
    color: ${props => props.theme.link.hover};
  }
`
