import { createGlobalStyle } from 'styled-components'
import { colors } from './colors'

export default createGlobalStyle`
/* BODY STYLES */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  scrollbar-width: thin;
  scrollbar-color: #babac0 rgba(0, 0, 0, 0);

  ::-webkit-scrollbar {
    background-color: rgba(0, 0, 0, 0);
    width: 15px;
    border-radius: 10px;
    opacity: 0;
  }
  
  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
    color: rgba(0, 0, 0, 0);
    opacity: 0;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }
}

body,
input,
button,
textarea,
ul,
li {
  font-family: 'Inter', sans-serif;
}

body {
  background: ${props => props.theme.general.background};
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: ${props => props.theme.text.default};
}

p,
li,
label,
span {
  font-size: 14px;
  color: ${props => props.theme.text.default};
}

/* Remove default style from browser autofill */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -webkit-text-fill-color: ${props => props.theme.text.default};
}

input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

/* Remove arrows from input number */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
}

iframe {
  border: 0;
}
`

export const default_theme = {
  general: {
    background: 'white',
    text: colors['Gray 500'],
    filter_background: 'white'
  },

  text: {
    default: colors['Gray 600']
  },

  link: {
    default: colors['Gray 600'],
    hover: colors['Red 500']
  },

  transaction: {
    sale: colors['Red 300'],
    rent: colors['Green 400'],
    season: colors['Purple 300']
  },

  input: {
    color: colors['Red 700'],
    background: 'white',
    border: colors['Gray 300'],
    border_hover: colors['Gray 400'],
    border_active: colors['Blue 400'],
    border_error: colors['Red 500'],
    placeholder: colors['Gray 500'],
    placeholder_active: colors['Blue 400'],
    placeholder_error: colors['Red 500'],
    marker: '#e8e8e8',
    dropdown_background: 'white',
    dropdown_option_hover: colors['Blue 50'],
    dropdown_option_selected: colors['Blue 600'],

    button: colors['Gray 400'],
    button_hover: colors['Gray 400'],
    button_background_hover: colors['Gray 200'],
    button_height: '22px',
    button_width: '22px',
    button_padding: '5px'
  },

  button: {
    default_background: colors['Red 500'],
    default_background_hover: colors['Red 600'],
    default_text: 'white',
    regular_background: 'white',
    regular_background_hover: colors['Gray 200'],
    regular_text_hover: colors['Gray 600'],
    regular_border: colors['Gray 600'],
    secondary_background: colors['Blue 50'],
    secondary_text: colors['Blue 600'],
    secondary_background_hover: colors['Blue 100'],
    simple_background: 'white',
    simple_background_hover: colors['Blue 50'],
    simple_text: colors['Blue 500'],
    simple_text_hover: colors['Blue 600']
  },

  loader: {
    input: colors['Gray 600'],
    button: colors['Gray 100']
  },

  date_picker: {
    background: 'white',
    border: colors['Gray 200'],
    divisor: colors['Gray 300'],
    button_color: colors['Gray 400'],
    button_color_hover: colors['Gray 200'],
    option_background: colors['Blue 50'],
    option_color: colors['Blue 600']
  },

  cards: {
    background: 'white',
    border: colors['Gray 300'],
    border_hover: colors['Blue 400'],
    icon: colors['Gray 600'],
    text: colors['Gray 400'],
    text_hover: colors['Blue 400'],
    subtext: colors['Gray 500']
  },

  footer: {
    background: colors['Gray 800'],
    text: colors['Gray 100'],
    text_hover: colors['Red 400']
  }
}
