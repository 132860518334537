import API from './api'

//Properties

export const get_cities = () => API.get('/cities')

export const get_districts = (cityID, transaction) => {
  return API.get(`/districts/`, {
    params: { city_id: cityID, transaction: transaction }
  })
}

export const get_destaques = (page, page_items, transaction) =>
  API.get(`/property/featured`, {
    params: { page: page, page_items: page_items, transaction: transaction }
  })

export const get_property = (idProp, transaction) => API.get(`/property/${transaction}/${idProp}`)

export const get_images = id => API.get(`/properties/${id}/image`)

export const getFilteredProperties = params => API.get(`/property/filter`, { params: params })

export const get_types = () => API.get('/propertyType')

export const postNewsletter = email => API.post('/email/newsletter', { email })

export const sendEmail = body =>
  API.post('/email/contact', {
    email: 'imobiliariatoni@imobiliariatoni.com.br',
    body,
    title: 'Imob: Novo Contato'
  })

export const sendToEmployee = (body, name, email) =>
  API.post('/email/property', { body, name, email })

export const getEmployees = () => API.get('/properties/employees')

export const getAbout = () => API.get('/properties/dadosImob')

/* ADMIN */

export const login = (username, password) =>
  API.post('/admin/users/login', { usuário: username, senha: password })

export const updatePassword = (username, newPassword) =>
  API.post('/admin/users/updatePassword', {
    usuário: username,
    senha: newPassword
  })
