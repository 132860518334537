import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 80px;
  width: 100%;
  font-size: 15px;

  & img {
    height: 45px;
  }

  border-bottom: 3px solid ${props => props.theme.transaction[props.type]};
`

export const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;

  @media (max-width: 500px) {
    display: none;
  }
`
