import React, { useState, useEffect } from 'react'
import { Title, Icon, Description } from './styles'
import { PropertyCard, ContainerFlex, ContainerGrid, Loader } from '../'
import { get_destaques } from '../../services/requests'
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io'

export const Featured = ({ transaction, setTransaction }) => {
  const [page, setPage] = useState(0)
  const page_items = 10
  const [totalPages, SetTotalPages] = useState(0)
  const [data, setData] = useState(null)

  const getInitialData = async () => {
    try {
      let promise = await get_destaques(page, page_items, transaction)
      setData(promise.data.properties)
      SetTotalPages(promise.data.total_pages)
    } catch (error) {
      console.log(error)
    }
  }

  const nextPage = async () => {
    if (page < totalPages) {
      setData(null)
      setPage(page + 1)

      try {
        let promise = await get_destaques(page + 1, page_items, transaction)
        setData(promise.data.properties)
      } catch (error) {
        console.log(error)
      }
    }
  }

  const prevPage = async () => {
    if (page > 0) {
      setData(null)
      setPage(page - 1)

      try {
        let promise = await get_destaques(page - 1, page_items, transaction)
        setData(promise.data.properties)
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleTransaction = async () => {
    setPage(0)
    SetTotalPages(0)
    setData(null)
    getInitialData()
  }

  useEffect(() => {
    getInitialData()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleTransaction()
    // eslint-disable-next-line
  }, [transaction])

  return (
    <ContainerFlex margin='40px auto' width='90vw' direction='column'>
      <Title>Imóveis em Destaque</Title>
      <Description>Conheça nossos imóveis em destaque, com as melhores condições</Description>
      {data ? (
        <ContainerFlex width='100%' direction='row' align='center' gap='10px'>
          <Icon disabled={page === 0}>
            <IoIosArrowBack onClick={prevPage} />
          </Icon>
          <ContainerGrid width='calc(100% - 90px)' min='200px' gap='15px' justify='center'>
            {data.length > 0 &&
              data.map(item => {
                return <PropertyCard data={item} transaction={transaction} key={item.id} />
              })}
          </ContainerGrid>
          <Icon disabled={page === totalPages}>
            <IoIosArrowForward onClick={nextPage} />
          </Icon>
        </ContainerFlex>
      ) : (
        <ContainerFlex margin='30px auto'>
          <Loader />
        </ContainerFlex>
      )}
    </ContainerFlex>
  )
}
