import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Card = styled(Link)`
  width: 200px;
  cursor: pointer;
  background: white;
  transition: all 0.4s;
  border: 1px solid ${props => props.theme.cards.border};

  & span {
    font-size: 10px;
    color: ${props => props.theme.cards.subtext};
  }

  &:hover {
    border: 1px solid ${props => props.theme.transaction[props.transaction]};
  }

  @media (max-width: 499px) {
    width: 100%;
  }
`

export const Image = styled.img`
  width: 100%;
  height: 130px;
  object-fit: cover;
`

export const NoImage = styled.div`
  width: 100%;
  height: 130px;

  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    width: 100px;
  }
`

export const Title = styled.h3`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 5px;
`

export const SubTitle = styled.p`
  font-size: 12px;
`

export const IconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Icon = styled.div`
  display: flex;

  & svg {
    margin-right: 10px;
    color: ${props => props.theme.transaction[props.transaction]};
  }
`

export const Price = styled.p`
  margin-top: 5px;
  font-size: ${props => (props.season ? '12px' : '13px')};
  font-weight: 500;
`
